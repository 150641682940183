import React from 'react';
import './App.css';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAlgSFwiD9hg2-gHLDh6fmRCR5R-BBMvHM",
  authDomain: "techmar-solutions.firebaseapp.com",
  projectId: "techmar-solutions",
  storageBucket: "techmar-solutions.appspot.com",
  messagingSenderId: "508707017220",
  appId: "1:508707017220:web:af77618d7b62e609f4db51",
  measurementId: "G-GN8ECJ3BY1"
};

function App() {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const LOGO_URL = "https://firebasestorage.googleapis.com/v0/b/techmar-solutions.firebasestorage.app/o/techmarcr.com%2Fimages%2Fanimate_logo.gif?alt=media&token=0229da62-b15b-4a71-92ac-53cac3513edd";
  return (
    <div className="App">
      <header className="App-header">
        <img src={LOGO_URL} className="App-logo" alt="logo" />
        <h1>
          Techmar Solutions CR
        </h1>
        <h2>
          Page in progress...
        </h2>
      </header>
    </div>
  );
}

export default App;
